import { FC, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Tag from 'types/tag';

interface ChildComponentProps {
    tag?: Tag;
    onAdd(tag: Tag): any;
    onClose(): any;
}

const TagAdd: FC<ChildComponentProps> = (props) => {
    const { tag, onAdd, onClose } = props;
    const [open, setOpen] = useState(false);
    const [currentTag, setCurrentTag] = useState(tag);
    const { t } = useTranslation();
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (tag === undefined) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [tag]);

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title"> {t('ADD_NEW_TAG')} </DialogTitle>
                <DialogContent sx={{ paddingTop: '10px!important', width: mobileDevice ? window.innerWidth - 65 : 500 }}>
                    <Grid container>
                        <Grid item md={12} xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth required>
                                <InputLabel>{t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={currentTag?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentTag!,
                                            name: d.target.value
                                        };
                                        setCurrentTag(e);
                                    }}
                                    type="text"
                                    label={t('NAME')}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Typography marginTop="5px"> * {t('MANDATORY_FIELDS')}</Typography>
                </DialogContent>
                <DialogActions sx={{ marginRight: '16px' }}>
                    <Button
                        onClick={() => {
                            setCurrentTag(undefined);
                            onClose();
                        }}
                        autoFocus
                        style={{
                            border: '2px solid',
                            borderColor: theme.palette.secondary.main,
                            borderRadius: 7
                        }}
                    >
                        <Typography sx={{ color: theme.palette.primary.main }}> {t('CANCEL')}</Typography>
                    </Button>
                    <Button
                        style={{
                            border: '2px solid',
                            borderColor: theme.palette.primary.main,
                            borderRadius: 7,
                            background: theme.palette.primary.main
                        }}
                        onClick={() => {
                            setCurrentTag(undefined);
                            onAdd(currentTag!);
                        }}
                    >
                        <Typography style={{ color: '#fff' }}>{t('ADD')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TagAdd;
