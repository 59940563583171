// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Chip, Divider, Grid, IconButton, Typography } from '@mui/material';

// assets
// import DeleteIcon from '@mui/icons-material/Delete';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BluetoothConnectedIcon from '@mui/icons-material/BluetoothConnected';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Asset from 'types/asset';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import SkeletonBody from 'ui-component/skeleton/SkeletonBody';
import { IconClipboardText, IconCalendarTime, IconBluetooth } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import Utilities from 'utils/utilities';
import darkThemeIcon from 'assets/images/halog-icon-dark-theme.png';
import lightThemeIcon from 'assets/images/halog-icon-light-theme.png';
import TagIcon from '@mui/icons-material/Tag';

interface AssetListMobileProps {
    assetsList: Asset[] | undefined;
    onEdit: (asset: Asset) => void;
}

const AssetListMobile: FC<AssetListMobileProps> = (props) => {
    const { assetsList, onEdit } = props;
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const theme = useTheme();
    const { locale } = useConfig();
    const navigate = useNavigate();
    const utilities = new Utilities();

    return (
        <Grid aria-labelledby="tableTitle">
            {!isLoading ? (
                <Grid>
                    {assetsList!.map((asset) => {
                        if (typeof asset === 'number') return null;

                        return (
                            <Grid
                                key={asset.id}
                                sx={{
                                    display: 'block',
                                    '& .MuiGrid-root': { paddingRight: 0 }
                                }}
                            >
                                <Grid container spacing={1} sx={{ mt: 2, ml: 0, pl: 2 }}>
                                    <Grid item xs={12} sx={{ pl: 20 }}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <IconClipboardText />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        Asset:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {asset.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pl: 20 }}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    {theme.palette.mode === 'dark' ? (
                                                        <img src={darkThemeIcon} style={{ width: '24px', height: '24px' }} alt="" />
                                                    ) : (
                                                        <img src={lightThemeIcon} style={{ width: '24px', height: '24px' }} alt="" />
                                                    )}
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('DEVICE_NAME')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {asset.halogName ? asset.halogName : '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pl: 20 }}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <IconBluetooth />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('PAIRED_STATUS')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {asset.halogPairDate && (
                                                            <BluetoothConnectedIcon sx={{ fontSize: '1.3rem' }} color="secondary" />
                                                        )}
                                                        {!asset.halogPairDate && (
                                                            <BluetoothDisabledIcon sx={{ fontSize: '1.3rem' }} color="error" />
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pl: 10, pr: 5 }}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <IconCalendarTime />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('PAIRED_DATE')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {asset.halogPairDate ? utilities.formatDate(asset.halogPairDate, locale) : '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {asset.tags && asset.tags.length > 0 && (
                                        <Grid item xs={12} sx={{ pl: 10, pr: 5 }}>
                                            <Grid container direction="column">
                                                <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                    <Grid item>
                                                        <TagIcon />
                                                    </Grid>
                                                    <Grid item sx={{ alignContent: 'center' }}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                        >
                                                            {t('Tags')}:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item sx={{ alignContent: 'center' }}>
                                                        {asset.tags.map((value) => (
                                                            <Chip color="secondary" key={value.id} label={value.name} size="small" />
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Divider sx={{ mx: 3 }} />
                                        <Grid
                                            container
                                            sx={{
                                                verticalAlign: 'bottom',
                                                textAlign: 'center',
                                                mt: 0.5
                                            }}
                                        >
                                            <Grid item xs={6} sx={{ mb: 1 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        color: 'secondary'
                                                    }}
                                                >
                                                    <IconButton color="secondary" size="large" onClick={() => onEdit(asset)}>
                                                        <EditTwoToneIcon sx={{ fontSize: '1.5rem' }} />
                                                    </IconButton>
                                                    {t('EDIT')}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sx={{ mb: 1 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        color: 'secondary'
                                                    }}
                                                >
                                                    <IconButton
                                                        color="secondary"
                                                        size="large"
                                                        onClick={() => navigate(`/dashboard?id=${asset.id}`)}
                                                    >
                                                        <DashboardIcon sx={{ fontSize: '1.5rem' }} />
                                                    </IconButton>
                                                    {t('VIEW_DATA')}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mx: 1 }} />
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <SkeletonBody numHeaders={1} showAction />
            )}
        </Grid>
    );
};

export default AssetListMobile;
