import * as React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    CardContent,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    debounce,
    Button,
    Chip
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
// import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { EnhancedTableHeadProps, HeadCell, ArrangementOrder } from 'types';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import SkeletonBody from 'ui-component/skeleton/SkeletonBody';
import useConfig from 'hooks/useConfig';
import Utilities from 'utils/utilities';
import { useDispatch } from 'store';
import { startSnackbar } from 'store/slices/snackbar';
import { v4 as uuidv4 } from 'uuid';
import Tag from 'types/tag';
import TagService from 'services/TagService';
import TagFilter from 'types/filters/tagFilter';
import TagListMobile from './tagListMobile';
import TagAdd from './tagAdd';
import TagEdit from './tagEdit';

// table header options
const headCells: HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        label: 'NAME',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface TagListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selected
}: TagListEnhancedTableHeadProps) {
    const { t } = useTranslation();
    const currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <TableHead>
            <TableRow>
                {!mobileDevice &&
                    headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {t(headCell.label)}
                        </TableCell>
                    ))}
                {numSelected <= 0 && (
                    <TableCell
                        sortDirection={false}
                        align="right"
                        sx={{ paddingRight: windowWidth < 1400 ? '1.5%!important' : '2%!important' }}
                    >
                        {t('ACTIONS')}
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TAG LIST ||============================== //

const TagList = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const { t } = useTranslation();
    const { locale } = useConfig();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const utilities = new Utilities();

    const [order, setOrder] = useState<ArrangementOrder>('asc');
    const [orderBy, setOrderBy] = useState<string>('name');
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [searchText, setSearchText] = useState<string>('');
    const [rows, setRows] = useState<Tag[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [tagToEdit, setTagToEdit] = useState<Tag | undefined>(undefined);
    const [tagToAdd, setTagToAdd] = useState<Tag | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [fliterDialogModalOpen, setFliterDialogModalOpen] = useState(false);
    const tagService = new TagService();

    useEffect(() => {
        (async () => {
            if (user) {
                const tagFilter = getTagFilter();
                await fetchRow(tagFilter);
            }
        })();
    }, [user, page, rowsPerPage, order, orderBy, searchText]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
    }, []);

    const fetchRow = useCallback(
        debounce(async (tagFilter: TagFilter) => {
            setIsLoading(true);
            const paginatedTags = await tagService.getTagsPaginated(tagFilter);
            setIsLoading(false);
            setRows(paginatedTags.items);
            setTotalElements(paginatedTags.totalCount);
        }, 700),
        []
    );

    const getTagFilter = () => {
        const tagFilter: TagFilter = {
            orderAscending: order?.toString() === 'asc',
            orderBy: orderBy,
            page: page + 1,
            recordsPerPage: rowsPerPage,
            searchString: searchText,
            tenantId: user?.tenantId!
        };
        return tagFilter;
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const newString = event?.target.value;
        setSearchText(newString || '');
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedId = rows.map((n) => n.name);
            setSelected(newSelectedId);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const onAdd = async (tag: Tag) => {
        if (tag && utilities.isNotNullOrEmpty(tag.name)) {
            setTagToAdd(undefined);
            if (user) {
                const response = await tagService.addTag(tag, user!.tenantId);
                if (response) {
                    const tagFilter = getTagFilter();
                    dispatch(startSnackbar(t('TAG_ADDED_SUCCESSFULLY'), 'success'));
                    await fetchRow(tagFilter);
                }
            }
        } else {
            dispatch(startSnackbar(t('NAME_IS_MANDATORY'), 'error'));
        }
    };

    const onSave = async (tag: Tag) => {
        if (tag && utilities.isNotNullOrEmpty(tag.name)) {
            setTagToEdit(undefined);
            const response = await tagService.editTag(tag);
            if (response) {
                const tagFilter = getTagFilter();
                await fetchRow(tagFilter);
                dispatch(startSnackbar(t('TAG_EDITED_SUCCESSFULLY'), 'success'));
            }
        } else {
            dispatch(startSnackbar(t('NAME_IS_MANDATORY'), 'error'));
        }
    };

    const onDelete = async (tag: Tag) => {
        setTagToEdit(undefined);
        const result = await tagService.deleteTag(tag.id);
        if (result) {
            const tagFilter = getTagFilter();
            await fetchRow(tagFilter);
            dispatch(startSnackbar(t('TAG_DELETED_SUCCESSFULLY'), 'success'));
        } else {
            dispatch(startSnackbar(t('ERROR_DURING_DELETE_TAG'), 'error'));
        }
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const handleChangeOrder = (e: any) => {
        const { value } = utilities.getFormValue(e);
        setOrderBy(value);
    };

    const handleChangeOrderMethod = (e: any) => {
        const { value } = utilities.getFormValue(e);
        setOrder(value === 'asc' ? 'asc' : 'desc');
    };

    const setDefaultFilters = () => {
        setOrder('asc');
        setOrderBy('name');
    };

    return (
        <MainCard title="Tags" content={false}>
            <CardContent>
                <Grid container justifyContent="space-between" sx={{ mb: '1vh' }}>
                    <Drawer
                        anchor="right"
                        open={fliterDialogModalOpen}
                        onClose={() => setFliterDialogModalOpen(false)}
                        PaperProps={{
                            sx: { width: { xs: '75%', md: '50%', lg: '25%' } }
                        }}
                    >
                        <Grid container direction="column" spacing={2} sx={{ p: '15px' }}>
                            <Grid item justifyContent="center" textAlign="center">
                                <Grid container direction="row" alignItems="center">
                                    <Typography textAlign="center" variant="h5">
                                        {t('ADVANCED_FILTERS')}
                                    </Typography>
                                    <Tooltip title={t('CLOSE')}>
                                        <IconButton
                                            sx={{ marginLeft: 'auto', p: 0 }}
                                            onClick={() => {
                                                setFliterDialogModalOpen(false);
                                            }}
                                        >
                                            <HighlightOffIcon color="error" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth size="small">
                                    <InputLabel>{t('ORDER_BY')}</InputLabel>
                                    <Select label={t('ORDER_BY')} name="orderFieldName" onChange={handleChangeOrder} value={orderBy}>
                                        <MenuItem value="name">{t('NAME')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth size="small">
                                    <InputLabel>{t('ORDER_METHOD')}</InputLabel>
                                    <Select
                                        label={t('ORDER_METHOD')}
                                        name="isDescending"
                                        onChange={handleChangeOrderMethod}
                                        sx={{ color: 'white' }}
                                        value={order}
                                    >
                                        <MenuItem value="asc">{t('ASCENDING')}</MenuItem>
                                        <MenuItem value="desc">{t('DESCENDING')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Button
                                    startIcon={<CloseIcon fontSize="large" />}
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        setDefaultFilters();
                                        setFliterDialogModalOpen(false);
                                    }}
                                >
                                    {t('REMOVE_FILTERS')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Drawer>

                    <Grid item lg={12} xs={12} container>
                        <Grid lg={4} md={4} item xs={6}>
                            <TextField
                                fullWidth
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleSearch}
                                placeholder={mobileDevice ? t('SEARCH') : t('SEARCH_TAG')}
                                value={searchText}
                            />
                        </Grid>
                        <Grid lg={4} md={4} item xs={2} zeroMinWidth />
                        <Grid
                            lg={4}
                            md={4}
                            xs={4}
                            item
                            sx={{ display: 'flex', justifyContent: mobileDevice ? 'space-around' : 'flex-end' }}
                        >
                            <Grid
                                item
                                lg={2}
                                md={2}
                                xs={windowWidth < 400 ? 3 : 4}
                                sx={{
                                    display: 'flex',
                                    justifyContent: windowWidth < 400 ? 'flex-start' : 'flex-end',
                                    textAlign: 'right'
                                }}
                            >
                                <Stack direction="row" spacing={-1}>
                                    <Tooltip title={t('FILTERS')}>
                                        <IconButton onClick={() => setFliterDialogModalOpen(true)}>
                                            <FilterAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: 'right', paddingTop: 0.9 }}>
                                <Tooltip title={t('ADD_ASSET')}>
                                    <IconButton
                                        className="addButton"
                                        sx={{ backgroundColor: theme.palette.primary.main }}
                                        size="small"
                                        onClick={() =>
                                            setTagToAdd({
                                                id: uuidv4(),
                                                name: '',
                                                linkedElementsCount: 0
                                            })
                                        }
                                    >
                                        <AddIcon sx={{ color: '#fff', fontSize: '17px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {rows ? (
                <>
                    {mobileDevice ? (
                        <TagListMobile tagsList={rows} onEdit={(row) => setTagToEdit(row)} />
                    ) : (
                        <TableContainer sx={{ pl: '2px', pr: '2px' }}>
                            <Table
                                stickyHeader
                                sx={{
                                    minWidth: 750,
                                    '& .MuiTableCell-root': { padding: '2px 8px' }
                                }}
                                aria-labelledby="tableTitle"
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    selected={selected}
                                />
                                {!isLoading ? (
                                    <TableBody>
                                        {rows.map((row, index) => {
                                            if (typeof row === 'number') return null;
                                            const isItemSelected = isSelected(row.name);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell component="th" id={labelId} scope="row" sx={{ cursor: 'pointer' }}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                        >
                                                            {row.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ pr: 3, '& .MuiTableCell-root': { padding: '4px 8px' } }}>
                                                        <Tooltip title={t('EDIT')}>
                                                            <IconButton color="secondary" size="large" onClick={() => setTagToEdit(row)}>
                                                                <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                ) : (
                                    <SkeletonBody numHeaders={1} showAction />
                                )}
                            </Table>
                        </TableContainer>
                    )}
                </>
            ) : (
                <Skeleton variant="rectangular" width={210} height={118} />
            )}
            {/* table pagination */}
            <TablePagination
                sx={{
                    ...(mobileDevice && {
                        display: 'flex',
                        '& .MuiTablePagination-actions': {
                            marginLeft: 0
                        },
                        '& .MuiInputBase-root': {
                            marginRight: 1
                        },
                        '& .MuiIconButton-root': {
                            padding: '6px'
                        }
                    })
                }}
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('ROWS_PER_PAGE')}
            />
            <TagEdit tag={tagToEdit} onSave={onSave} onDelete={onDelete} onClose={() => setTagToEdit(undefined)} />
            <TagAdd tag={tagToAdd} onAdd={onAdd} onClose={() => setTagToAdd(undefined)} />
        </MainCard>
    );
};

export default TagList;
