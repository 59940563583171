// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';

import ReportIcon from '@mui/icons-material/Report';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import SkeletonBody from 'ui-component/skeleton/SkeletonBody';
import { IconClipboardText } from '@tabler/icons';
import PinIcon from '@mui/icons-material/Pin';
import Gateway from 'types/gateway';

interface GatewayListMobileProps {
    gatewaysList: Gateway[] | undefined;
    onEdit: (gateway: Gateway) => void;
}

const GatewayListMobile: FC<GatewayListMobileProps> = (props) => {
    const { gatewaysList, onEdit } = props;
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const theme = useTheme();

    return (
        <Grid aria-labelledby="tableTitle">
            {!isLoading ? (
                <Grid>
                    {gatewaysList!.map((gateway) => {
                        if (typeof gateway === 'number') return null;

                        return (
                            <Grid
                                key={gateway.id}
                                sx={{
                                    display: 'block',
                                    '& .MuiGrid-root': { paddingRight: 0 }
                                }}
                            >
                                <Grid container spacing={1} sx={{ mt: 2, ml: 0, pl: 2 }}>
                                    <Grid item xs={12} sx={{ pl: 20 }}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <IconClipboardText />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        Gateway:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {gateway.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pl: 20 }}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <PinIcon />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        Id:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                                                            fontFamily: 'monospace',
                                                            border: '1px solid black',
                                                            marginRight: '20px',
                                                            backgroundColor: theme.palette.mode === 'dark' ? '#3d4c81' : '#d9dedb'
                                                        }}
                                                    >
                                                        {gateway.id}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pl: 20 }}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={1} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <ReportIcon />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('MAINTENANCE_MODE')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {gateway.maintenanceMode && (
                                                            <ReportIcon sx={{ fontSize: '1.3rem' }} color="secondary" />
                                                        )}
                                                        {!gateway.maintenanceMode && (
                                                            <ReportOffIcon sx={{ fontSize: '1.3rem' }} color="error" />
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider sx={{ mx: 3 }} />
                                        <Grid
                                            container
                                            sx={{
                                                verticalAlign: 'bottom',
                                                textAlign: 'center',
                                                mt: 0.5
                                            }}
                                        >
                                            <Grid item xs={12} sx={{ mb: 1 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        color: 'secondary'
                                                    }}
                                                >
                                                    <IconButton color="secondary" size="large" onClick={() => onEdit(gateway)}>
                                                        <EditTwoToneIcon sx={{ fontSize: '1.5rem' }} />
                                                    </IconButton>
                                                    {t('EDIT')}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mx: 1 }} />
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <SkeletonBody numHeaders={1} showAction />
            )}
        </Grid>
    );
};

export default GatewayListMobile;
