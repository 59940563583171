import Asset from 'types/asset';
import { FC, useCallback, useEffect, useState } from 'react';
import {
    Autocomplete,
    Button,
    Chip,
    debounce,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SelectOption from 'types/selectOption';
import Utilities from 'utils/utilities';
import frequencyConfList from 'types/frequencyConf';
import ConfirmatioDialog from 'ui-component/ConfirmationDialog';
import Tag from 'types/tag';
import { v4 as uuidv4 } from 'uuid';
import TagService from 'services/TagService';
import useAuth from 'hooks/useAuth';
import { dispatch } from 'store';
import { startSnackbar } from 'store/slices/snackbar';

interface ChildComponentProps {
    asset?: Asset;
    onSave(asset: Asset): any;
    onDelete(asset: Asset): any;
    onClose(): any;
}

const AssetEdit: FC<ChildComponentProps> = (props) => {
    const { asset, onSave, onDelete, onClose } = props;
    const { user } = useAuth();
    const [open, setOpen] = useState(false);
    const [currentAsset, setCurrentAsset] = useState<Asset | undefined>(asset);
    const [tags, setTags] = useState<Tag[]>([]);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const [tagToSearch, setTagToSearch] = useState<string>('');
    const theme = useTheme();
    const { t } = useTranslation();
    const [dialogDelete, setDialogDelete] = useState(false);
    const utilities = new Utilities();
    const frequencyConf: SelectOption[] = frequencyConfList(t);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
    const tagService = new TagService();
    const [acceleration, setAcceleration] = useState<string>('');
    const [removedTags, setRemovedTags] = useState<Tag[]>([]);

    useEffect(() => {
        (async () => {
            if (asset?.acceleration !== undefined) {
                setAcceleration(asset.acceleration.toFixed(2));
            }
            if (asset === undefined) setOpen(false);
            else {
                setOpen(true);
                setCurrentAsset(asset);
                await fetchTags();
                setSelectedTags(asset.tags);
            }
        })();
    }, [asset]);

    useEffect(() => {
        const e = {
            ...currentAsset!,
            tags: selectedTags
        };
        setCurrentAsset(e);
    }, [selectedTags]);

    useEffect(() => {
        (async () => {
            if (asset !== undefined) {
                await fetchTags();
            }
        })();
    }, [asset, tagToSearch]);

    const fetchTags = useCallback(
        debounce(async () => {
            if (asset && asset.id) {
                const tagResult = await tagService.getAssetTags(tagToSearch, asset!.id, user?.tenantId!);
                setTags(tagResult);
            }
        }, 700),
        [tagToSearch, user?.tenantId, asset]
    );

    const handleTagChange = (_: React.SyntheticEvent, newValue: Tag[]) => {
        const uniqueTags = newValue
            .map((tag) => ({ ...tag, name: tag.name.trim() }))
            .filter((tag, index, self) => index === self.findIndex((t) => t.name.toLowerCase() === tag.name.toLowerCase()));
        setSelectedTags(uniqueTags);

        const removedTags = selectedTags.filter((tag) => !uniqueTags.some((selectedTag) => selectedTag.id === tag.id));
        setRemovedTags((prev) => {
            const updatedTags = [...prev, ...removedTags].filter(
                (tag, index, self) =>
                    index === self.findIndex((t) => t.id === tag.id || t.name.trim().toLowerCase() === tag.name.trim().toLowerCase())
            );
            return updatedTags;
        });
        setTags((prev) => {
            const uniqueTags = [...prev, ...removedTags].filter(
                (tag, index, self) => index === self.findIndex((t) => t.name.trim().toLowerCase() === tag.name.trim().toLowerCase())
            );
            return uniqueTags;
        });
    };

    const handleTagInputChange = (event: React.KeyboardEvent | React.FocusEvent, value: string) => {
        const trimmedValue = value.trim().toLowerCase();
        const containsComma = trimmedValue.includes(',');

        if (containsComma) {
            dispatch(startSnackbar(t('ERROR_ADDING_TAG_REMOVE_COMMA'), 'error'));
            return;
        }

        setTagToSearch(value);

        // Verifica se l'input è valido
        if (trimmedValue && !containsComma && (event.type === 'blur' || (event as React.KeyboardEvent).key === 'Enter')) {
            const existingTag = tags.find((tag) => tag.name.trim().toLowerCase() === trimmedValue);
            const alreadySelected = selectedTags.some((tag) => tag.name.trim().toLowerCase() === trimmedValue);

            if (!alreadySelected) {
                if (existingTag) {
                    setRemovedTags((prev) => prev.filter((tag) => tag.id !== existingTag.id));
                    setSelectedTags((prev) => [...prev, existingTag]);
                } else {
                    const newTag: Tag = { id: uuidv4(), name: trimmedValue };
                    setTags((prev) => [...prev, newTag]);
                    setSelectedTags((prev) => [...prev, newTag]);
                }
            }
        }
    };

    const handleFrequency = (e: any) => {
        const { value } = utilities.getFormValue(e);
        const assetCopy = {
            ...currentAsset!,
            frequency: parseFloat(value)
        };
        setCurrentAsset(assetCopy);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t('EDIT')} asset {asset?.name}
                </DialogTitle>
                <DialogContent sx={{ paddingTop: '10px!important', width: mobileDevice ? window.innerWidth - 65 : 500 }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <InputLabel> {t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={asset?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentAsset!,
                                            name: d.target.value
                                        };
                                        setCurrentAsset(e);
                                    }}
                                    label={t('NAME')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('FREQUENCY')}</InputLabel>
                                <Select
                                    label={t('FREQUENCY')}
                                    name="frequency"
                                    onChange={handleFrequency}
                                    sx={{ color: 'white' }}
                                    value={Math.floor(currentAsset?.frequency!)}
                                >
                                    {frequencyConf.map((frequency) => (
                                        <MenuItem value={frequency.value} key={frequency.value}>
                                            {t(frequency.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('ACCELERATION')}</InputLabel>
                                <OutlinedInput
                                    value={acceleration}
                                    onBlur={(e) => {
                                        let value = parseFloat(e.target.value || '0').toFixed(2);
                                        if (isNaN(Number(value))) {
                                            value = '0';
                                        }
                                        setAcceleration(value);
                                        const updatedAsset = {
                                            ...currentAsset!,
                                            acceleration: parseFloat(value)
                                        };
                                        setCurrentAsset(updatedAsset);
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setAcceleration(value);
                                    }}
                                    type="number"
                                    label={t('ACCELERATION')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Autocomplete
                                multiple
                                autoHighlight
                                id="size-small-filled-multi"
                                size="small"
                                options={[
                                    ...tags,
                                    ...removedTags.filter(
                                        (tag) =>
                                            !tags.some(
                                                (existingTag) => existingTag.name.trim().toLowerCase() === tag.name.trim().toLowerCase()
                                            )
                                    )
                                ].filter(
                                    (tag) =>
                                        !selectedTags.some(
                                            (selected) => selected.name.trim().toLowerCase() === tag.name.trim().toLowerCase()
                                        )
                                )}
                                getOptionLabel={(option) => option.name}
                                value={selectedTags}
                                onChange={handleTagChange}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        const { key, ...tagProps } = getTagProps({ index });
                                        return <Chip color="secondary" key={key} label={option.name} size="small" {...tagProps} />;
                                    })
                                }
                                onInputChange={(event: any, value, reason) => {
                                    if (reason === 'input') handleTagInputChange(event, value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Tags"
                                        onKeyDown={(event: any) => handleTagInputChange(event, event.target.value)}
                                        onBlur={(event) => handleTagInputChange(event, event.target.value)}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Typography marginTop="5px"> * {t('MANDATORY_FIELDS')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        sx={{ alignItems: 'center', justifyContent: 'space-between', paddingLeft: '15px', paddingRight: '15px' }}
                    >
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: theme.palette.error.main,
                                    borderRadius: 7,
                                    border: '2px solid',
                                    borderColor: theme.palette.error.main
                                }}
                                onClick={() => setDialogDelete(true)}
                            >
                                <Typography sx={{ color: 'white' }}> {t('DELETE')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => {
                                    setTags([]);
                                    setSelectedTags([]);
                                    onClose();
                                }}
                                autoFocus
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.secondary.main,
                                    borderRadius: 7,
                                    marginRight: 10
                                }}
                            >
                                <Typography sx={{ color: theme.palette.primary.main }}> {t('CANCEL')}</Typography>
                            </Button>
                            <Button
                                onClick={() => {
                                    setTags([]);
                                    setSelectedTags([]);
                                    onSave(currentAsset!);
                                }}
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.primary.main,
                                    borderRadius: 7,
                                    background: theme.palette.primary.main
                                }}
                            >
                                <Typography sx={{ color: '#fff' }}> {t('SAVE')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <ConfirmatioDialog
                isVisible={dialogDelete}
                onClose={() => {
                    setDialogDelete(false);
                }}
                onConfirm={() => {
                    setDialogDelete(false);
                    onDelete(asset!);
                }}
            />
        </>
    );
};

export default AssetEdit;
