// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';

// import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BusinessIcon from '@mui/icons-material/Business';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import SkeletonBody from 'ui-component/skeleton/SkeletonBody';
import User from 'types/user';

interface UserListMobileProps {
    userList: User[] | undefined;
    onEdit: (user: User) => void;
}

const UserListMobile: FC<UserListMobileProps> = (props) => {
    const { userList, onEdit } = props;
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const theme = useTheme();

    return (
        <Grid aria-labelledby="tableTitle">
            {!isLoading ? (
                <Grid>
                    {userList!.map((user) => {
                        if (typeof user === 'number') return null;

                        return (
                            <Grid
                                key={user.id}
                                sx={{
                                    display: 'block',
                                    '& .MuiGrid-root': { paddingRight: 0 }
                                }}
                            >
                                <Grid container spacing={1} sx={{ mt: 2, ml: 0, pl: '4px' }}>
                                    <Grid item xs={12}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={0.2} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <PersonIcon />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('NAME')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {user.name ? user.name : '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={0.2} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <PersonIcon />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('SURNAME')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {user.surname ? user.surname : '-'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={0.2} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <EmailIcon />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('Email')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center', marginRight: '18px', overflowWrap: 'anywhere' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                                                            flexWrap: 'wrap'
                                                        }}
                                                    >
                                                        {user.username}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={0.2} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <BusinessIcon />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('COMPANY')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {user.company}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ flexFlow: 'noWrap' }} spacing={0.2} sx={{ mb: 1 }}>
                                                <Grid item>
                                                    <SupervisorAccountIcon />
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {t('IS_SYSTEM_ADMIN')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ alignContent: 'center' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                    >
                                                        {user.isSystemAdmin && <DoneIcon sx={{ fontSize: '1.3rem' }} color="secondary" />}
                                                        {!user.isSystemAdmin && <CloseIcon sx={{ fontSize: '1.3rem' }} color="error" />}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider sx={{ mx: 3 }} />
                                        <Grid
                                            container
                                            sx={{
                                                verticalAlign: 'bottom',
                                                textAlign: 'center',
                                                mt: 0.5
                                            }}
                                        >
                                            <Grid item xs={12} sx={{ mb: 1 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        color: 'secondary'
                                                    }}
                                                >
                                                    <IconButton color="secondary" size="large" onClick={() => onEdit(user)}>
                                                        <EditTwoToneIcon sx={{ fontSize: '1.5rem' }} />
                                                    </IconButton>
                                                    {t('EDIT')}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mx: 1 }} />
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <SkeletonBody numHeaders={1} showAction />
            )}
        </Grid>
    );
};

export default UserListMobile;
