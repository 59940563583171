import Halog from 'types/halog';
import { FC, useCallback, useEffect, useState } from 'react';
import {
    Autocomplete,
    Button,
    Chip,
    debounce,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Tag from 'types/tag';
import { v4 as uuidv4 } from 'uuid';
import TagService from 'services/TagService';
import useAuth from 'hooks/useAuth';
import { dispatch } from 'store';
import { startSnackbar } from 'store/slices/snackbar';

interface ChildComponentProps {
    halog?: Halog;
    onAdd(halog: Halog): any;
    onClose(): any;
}

const HalogAdd: FC<ChildComponentProps> = (props) => {
    const { halog, onAdd, onClose } = props;
    const { user } = useAuth();
    const [open, setOpen] = useState(false);
    const [halogCopy, setHalogCopy] = useState(halog);
    const { t } = useTranslation();
    const theme = useTheme();
    const [tags, setTags] = useState<Tag[]>([]);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
    const [tagToSearch, setTagToSearch] = useState<string>('');
    const [removedTags, setRemovedTags] = useState<Tag[]>([]);

    const tagService = new TagService();

    useEffect(() => {
        (async () => {
            if (halog === undefined) setOpen(false);
            else {
                setOpen(true);
                setHalogCopy(halog);
                await fetchTags();
                setSelectedTags(halog.tags);
            }
        })();
    }, [halog]);

    useEffect(() => {
        const e = {
            ...halogCopy!,
            tags: selectedTags
        };
        setHalogCopy(e);
    }, [selectedTags]);

    useEffect(() => {
        (async () => {
            if (halog !== undefined) {
                await fetchTags();
            }
        })();
    }, [halog, tagToSearch]);

    const fetchTags = useCallback(
        debounce(async () => {
            if (halog && halog.id) {
                const tagResult = await tagService.getHalogTags(tagToSearch, halog!.id, user?.tenantId!);
                setTags(tagResult);
            }
        }, 700),
        [tagToSearch, user?.tenantId, halog]
    );

    const handleTagChange = (_: React.SyntheticEvent, newValue: Tag[]) => {
        const uniqueTags = newValue
            .map((tag) => ({ ...tag, name: tag.name.trim() }))
            .filter((tag, index, self) => index === self.findIndex((t) => t.name.toLowerCase() === tag.name.toLowerCase()));
        setSelectedTags(uniqueTags);

        const removedTags = selectedTags.filter((tag) => !uniqueTags.some((selectedTag) => selectedTag.id === tag.id));
        setRemovedTags((prev) => {
            const updatedTags = [...prev, ...removedTags].filter(
                (tag, index, self) =>
                    index === self.findIndex((t) => t.id === tag.id || t.name.trim().toLowerCase() === tag.name.trim().toLowerCase())
            );
            return updatedTags;
        });
        setTags((prev) => {
            const uniqueTags = [...prev, ...removedTags].filter(
                (tag, index, self) => index === self.findIndex((t) => t.name.trim().toLowerCase() === tag.name.trim().toLowerCase())
            );
            return uniqueTags;
        });
    };

    const handleTagInputChange = (event: React.KeyboardEvent | React.FocusEvent, value: string) => {
        const trimmedValue = value.trim().toLowerCase();
        const containsComma = trimmedValue.includes(',');

        if (containsComma) {
            dispatch(startSnackbar(t('ERROR_ADDING_TAG_REMOVE_COMMA'), 'error'));
            return;
        }

        setTagToSearch(value);

        // Verifica se l'input è valido
        if (trimmedValue && !containsComma && (event.type === 'blur' || (event as React.KeyboardEvent).key === 'Enter')) {
            const existingTag = tags.find((tag) => tag.name.trim().toLowerCase() === trimmedValue);
            const alreadySelected = selectedTags.some((tag) => tag.name.trim().toLowerCase() === trimmedValue);

            if (!alreadySelected) {
                if (existingTag) {
                    setRemovedTags((prev) => prev.filter((tag) => tag.id !== existingTag.id));
                    setSelectedTags((prev) => [...prev, existingTag]);
                } else {
                    const newTag: Tag = { id: uuidv4(), name: trimmedValue };
                    setTags((prev) => [...prev, newTag]);
                    setSelectedTags((prev) => [...prev, newTag]);
                }
            }
        }
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title"> {t('ADD_NEW_DEVICE')} </DialogTitle>
                <DialogContent sx={{ paddingTop: '10px!important', width: mobileDevice ? window.innerWidth - 65 : 500 }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel>{t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={halog?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...halogCopy!,
                                            name: d.target.value
                                        };
                                        setHalogCopy(e);
                                    }}
                                    type="text"
                                    label={t('NAME')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel>{t('IDENTIFIER')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={halog?.identifier}
                                    onChange={(d) => {
                                        const e = {
                                            ...halogCopy!,
                                            identifier: d.target.value
                                        };
                                        setHalogCopy(e);
                                    }}
                                    type="text"
                                    label={t('IDENTIFIER')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Autocomplete
                                multiple
                                id="size-small-filled-multi"
                                size="small"
                                sx={{
                                    '& .MuiFilledInput-root': { backgroundColor: '#fff' },
                                    '& .MuiFilledInput-root:hover': { backgroundColor: '#fff' },
                                    '& #size-small-filled-multi': { backgroundColor: 'secondary' }
                                }}
                                options={tags.filter(
                                    (tag) => !selectedTags.some((selected) => selected.name.toLowerCase() === tag.name.toLowerCase())
                                )}
                                getOptionLabel={(option) => option.name}
                                value={selectedTags}
                                onChange={handleTagChange}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        const { key, ...tagProps } = getTagProps({ index });
                                        return <Chip color="secondary" key={key} label={option.name} size="small" {...tagProps} />;
                                    })
                                }
                                onInputChange={(event: any, value, reason) => {
                                    if (reason === 'input') handleTagInputChange(event, value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Tags"
                                        onKeyDown={(event: any) => handleTagInputChange(event, event.target.value)}
                                        onBlur={(event) => handleTagInputChange(event, event.target.value)}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Typography marginTop="5px"> * {t('MANDATORY_FIELDS')}</Typography>
                </DialogContent>
                <DialogActions sx={{ marginRight: '16px' }}>
                    <Button
                        onClick={() => {
                            setHalogCopy(undefined);
                            onClose();
                        }}
                        autoFocus
                        style={{
                            border: '2px solid',
                            borderColor: theme.palette.secondary.main,
                            borderRadius: 7
                        }}
                    >
                        <Typography sx={{ color: theme.palette.primary.main }}> {t('CANCEL')}</Typography>
                    </Button>
                    <Button
                        style={{
                            border: '2px solid',
                            borderColor: theme.palette.primary.main,
                            borderRadius: 7,
                            background: theme.palette.primary.main
                        }}
                        onClick={() => {
                            setHalogCopy(undefined);
                            onAdd(halogCopy!);
                        }}
                    >
                        <Typography style={{ color: '#fff' }}>{t('ADD')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default HalogAdd;
