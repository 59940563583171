import { FC, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ConfirmatioDialog from 'ui-component/ConfirmationDialog';
import Tag from 'types/tag';

interface ChildComponentProps {
    tag?: Tag;
    onSave(gateway: Tag): any;
    onDelete(gateway: Tag): any;
    onClose(): any;
}

const TagEdit: FC<ChildComponentProps> = (props) => {
    const { tag, onSave, onDelete, onClose } = props;
    const [open, setOpen] = useState(false);
    const [currentTag, setCurrentTag] = useState<Tag | undefined>(tag);
    const theme = useTheme();
    const { t } = useTranslation();
    const [dialogDelete, setDialogDelete] = useState(false);
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (tag === undefined) setOpen(false);
        else {
            setOpen(true);
            setCurrentTag(tag);
        }
    }, [tag]);

    useEffect(() => {}, [dialogDelete]);

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t('EDIT')} tag {tag?.name}
                </DialogTitle>
                <DialogContent sx={{ paddingTop: '10px!important', width: mobileDevice ? window.innerWidth - 65 : 500 }}>
                    <Grid container>
                        <Grid item md={12} xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <InputLabel> {t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={currentTag?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...currentTag!,
                                            name: d.target.value
                                        };
                                        setCurrentTag(e);
                                    }}
                                    label={t('NAME')}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Typography marginTop="5px"> * {t('MANDATORY_FIELDS')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        sx={{ alignItems: 'center', justifyContent: 'space-between', paddingLeft: '15px', paddingRight: '15px' }}
                    >
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: theme.palette.error.main,
                                    borderRadius: 7,
                                    border: '2px solid',
                                    borderColor: theme.palette.error.main
                                }}
                                onClick={() => setDialogDelete(true)}
                            >
                                <Typography sx={{ color: 'white' }}> {t('DELETE')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onClose}
                                autoFocus
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.secondary.main,
                                    borderRadius: 7,
                                    marginRight: 10
                                }}
                            >
                                <Typography sx={{ color: theme.palette.primary.main }}> {t('CANCEL')}</Typography>
                            </Button>
                            <Button
                                onClick={() => onSave(currentTag!)}
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.primary.main,
                                    borderRadius: 7,
                                    background: theme.palette.primary.main
                                }}
                            >
                                <Typography sx={{ color: '#fff' }}> {t('SAVE')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <ConfirmatioDialog
                isVisible={dialogDelete}
                alertText={currentTag != undefined && currentTag!.linkedElementsCount! > 0 ? 'THIS_IS_ASSOCIATED_WITH_OTHER_ELEMENTS' : ''}
                onClose={() => {
                    setDialogDelete(false);
                }}
                onConfirm={() => {
                    setDialogDelete(false);
                    onDelete(tag!);
                }}
            />
        </>
    );
};

export default TagEdit;
