import TagFilter from 'types/filters/tagFilter';
import { PaginationResult } from 'types/paginationResult';
import Tag from 'types/tag';
import axios from 'utils/axios';

export default class TagService {
    async getTags(tenantId: string): Promise<Tag[]> {
        try {
            const result = await axios.post<Tag[]>(`/GetTags`, tenantId);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch tag list');
        }
    }

    async getTagsPaginated(tagFilter: TagFilter): Promise<PaginationResult<Tag>> {
        try {
            const body = {
                page: tagFilter.page,
                recordsPerPage: tagFilter.recordsPerPage,
                searchString: tagFilter.searchString,
                orderBy: tagFilter.orderBy,
                orderAscending: tagFilter.orderAscending,
                tenantId: tagFilter.tenantId
            };
            const result = await axios.post<PaginationResult<Tag>>(`/GetTagsPaginated`, body);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch paginated tags');
        }
    }

    async addTag(tag: Tag, tenantId: string): Promise<boolean> {
        try {
            const response = await axios.post(`/AddTag`, {
                id: tag.id,
                name: tag.name,
                tenantId: tenantId
            });
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to add tag');
        }
    }

    async editTag(tag: Tag): Promise<boolean> {
        try {
            const response = await axios.post(`/EditTag`, tag);
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to edit tag');
        }
    }

    async deleteTag(tagId: string): Promise<boolean> {
        try {
            const response = await axios.post(`/DeleteTag`, tagId);
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to delete tag');
        }
    }

    async getAssetTags(searchString: string, assetId: string, tenantId: string): Promise<Tag[]> {
        try {
            const tagQuery = {
                name: searchString,
                id: assetId,
                tenantId: tenantId
            };
            const result = await axios.post<Tag[]>(`/GetAssetTags`, tagQuery);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch tag asset list');
        }
    }

    async getHalogTags(searchString: string, halogId: string, tenantId: string): Promise<Tag[]> {
        try {
            const tagQuery = {
                name: searchString,
                id: halogId,
                tenantId: tenantId
            };
            const result = await axios.post<Tag[]>(`/GetHalogTags`, tagQuery);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch tag halog list');
        }
    }
}
