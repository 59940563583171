import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';

interface ChildComponentProps {
    isVisible: boolean;
    alertText?: string;
    onClose(): any;
    onConfirm?(): void;
}

const ConfirmatioDialog: FC<ChildComponentProps> = (props) => {
    const { isVisible, alertText, onClose, onConfirm } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <>
            <Dialog open={isVisible} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{t('CONFIRM_DELETE')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t('ARE_YOU_SURE_TO_DELETE')}</DialogContentText>
                    {alertText && (
                        <Typography
                            sx={{ fontWeight: 'bold', color: 'red', fontSize: '14px', display: 'flex', marginTop: '6px' }}
                            id="alert-dialog-description"
                        >
                            <WarningIcon fontSize="small" />
                            {t(alertText)}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        style={{
                            border: '2px solid',
                            borderColor: theme.palette.secondary.main,
                            borderRadius: 7
                        }}
                    >
                        <Typography sx={{ color: theme.palette.primary.main }}> {t('NO')}</Typography>
                    </Button>
                    <Button
                        autoFocus
                        style={{
                            border: '2px solid',
                            borderColor: theme.palette.primary.main,
                            borderRadius: 7,
                            background: theme.palette.primary.main
                        }}
                        onClick={onConfirm}
                    >
                        <Typography sx={{ color: '#fff' }}> {t('YES')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmatioDialog;
