// material-ui
import { styled } from '@mui/material';

// third-parts
import { PowerBIEmbed } from 'powerbi-client-react';
import 'powerbi-report-authoring';

// project imports
import usePowerbi from 'hooks/usePowerbi';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { models } from 'powerbi-client';
import { PowerbiConfigType } from 'types/powerbi';
import DashboardService from 'services/DashboardService';
import config from '../../config';
import useAuth from 'hooks/useAuth';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { AppInitState } from '../../store/accountReducer';

const PowerbiContainer = styled('div')(({ theme }) => ({
    '& .power-bi': {
        width: '103.2%',
        height: 'calc(100vh - 90px)',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#fff',
        marginTop: '-20px !important',
        marginLeft: '-20px !important',
        marginBottom: '-20px !important',
        '& iframe': {
            border: '1px solid',
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
            borderRadius: '8px',
            position: 'absolute',
            top: '-42px'
        }
    }
}));

const Dashboard = () => {
    const { user } = useAuth();
    const { eventHandlers } = usePowerbi();
    const [searchParams] = useSearchParams();

    const powerbiService = new DashboardService();
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const [powerbiConfig, setPowerbiConfig] = useState<PowerbiConfigType>({
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: {
            layoutType: isMobile ? models.LayoutType.MobilePortrait : models.LayoutType.Master,
            localeSettings: {
                language: 'en',
                formatLocale: 'en-US'
            }
        }
    });

    async function setPowerBiState() {
        const reportConfig = await powerbiService.getPowerDashboard({
            reportId: config.powerBiConfig.reportId,
            workspaceId: config.powerBiConfig.workspaceId,
            tenantId: user?.tenantId
        });

        const assetFilter: models.IBasicFilter = {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: { table: 'Assets', column: 'Id' },
            operator: 'In',
            values: [searchParams.get('id')!],
            filterType: models.FilterType.Basic
        };

        setPowerbiConfig({
            embedUrl: reportConfig.embedUrl,
            accessToken: reportConfig.embedToken.token,
            filters: [assetFilter],
            settings: {
                panes: {
                    filters: {
                        expanded: false,
                        visible: false
                    }
                },
                layoutType: isMobile ? models.LayoutType.MobilePortrait : models.LayoutType.Master
            }
        });
    }

    useEffect(() => {
        setPowerBiState();
        return () => {
            setPowerBiState(); // This worked for me
        };
    }, []);

    return (
        // <MainCard sx={{ height: '100%' }} contentSX={{ '&:last-child': { pb: 0, mb: '-8px' } }}>
        <PowerbiContainer>
            <PowerBIEmbed
                embedConfig={powerbiConfig}
                eventHandlers={eventHandlers}
                cssClassName="power-bi"
                // getEmbeddedComponent={(embedObject: Embed) => setReport(embedObject as Report)}
            />
        </PowerbiContainer>
        // </MainCard>
    );
};

export default Dashboard;
