// types
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { ConfigProps } from 'types/config';

export const FIREBASE_API = {
    apiKey: 'AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM',
    authDomain: 'berry-material-react.firebaseapp.com',
    projectId: 'berry-material-react',
    storageBucket: 'berry-material-react.appspot.com',
    messagingSenderId: '901111229354',
    appId: '1:901111229354:web:a5ae5aa95486297d69d9d3',
    measurementId: 'G-MGJHSL8XW3'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/assets';

const odevConfig = window.odevConfig;

const location = window.location;
const hostName: string = `${location.protocol}//${location.host}`;

const authUrl: string = odevConfig?.IdentityServerUrl || process.env.REACT_APP_IDENTITY_SERVER_URL || '';
const serviceBaseUrl: string = odevConfig?.AppServiceBaseUrl || process.env.REACT_APP_SERVICE_BASE_URL || '';
const clientId: string = odevConfig?.IdentityServerClientId || process.env.REACT_APP_IDENTITY_CLIENT_ID || '';
const appVersion: string = odevConfig?.AppVersion || process.env.REACT_APP_VERSION || '';
const powerBiReportId: string = odevConfig?.PowerBiReportId || process.env.REACT_APP_POWER_BI_REPORT_ID || '';
const powerBiWorkspaceId: string = odevConfig?.PowerBiWorkspaceId || process.env.REACT_APP_POWER_BI_WORKSPACE_ID || '';
const swVersion: string = odevConfig?.SWVersion || process.env.REACT_APP_SW_VERSION || '';

const userManagerConfig: UserManagerSettings = {
    client_id: clientId,
    response_type: 'token id_token',
    scope: 'openid profile email',

    authority: authUrl,
    redirect_uri: `${hostName}/#/callback`,
    silent_redirect_uri: `${hostName}/#/silentRenew`,

    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: true,
    userStore: new WebStorageStateStore({ store: localStorage })
};

if (!authUrl) throw new Error("Missing env REACT_APP_IDENTITY_SERVER_URL or config value 'IdentityServerUrl'");
if (!serviceBaseUrl) throw new Error("Missing env REACT_APP_SERVICE_BASE_URL or config value 'AppServiceBaseUrl'");
if (!clientId) throw new Error("Missing env REACT_APP_IDENTITY_CLIENT_ID or config value 'IdentityServerClientId'");
if (!appVersion) throw new Error("Missing env REACT_APP_VERSION or config value 'appVersion'");
if (!swVersion) throw new Error("Missing env REACT_APP_SW_VERSION or config value 'SWVersion'");
if (!powerBiReportId) throw new Error("Missing env REACT_APP_POWER_BI_REPORT_ID or config value 'PowerBiApplicationId'");
if (!powerBiWorkspaceId) throw new Error("Missing env REACT_APP_POWER_BI_WORKSPACE_ID or config value 'PowerBiWorkspaceId'");

const config: ConfigProps = {
    navType: 'light', // light, dark
    locale: 'en', // 'en' - English, 'it' - Italian
    appVersion,
    swVersion,
    userManagerConfig,
    serviceBaseUrl,
    powerBiConfig: {
        reportId: powerBiReportId,
        workspaceId: powerBiWorkspaceId
    }
};

export default config;
